import { datadogRum } from '@datadog/browser-rum';
import defaultConfig from 'constants/defaultConfig';
import { getDevice, isSSRUserAgent } from 'utils';

export var dataDogRum = {
  isMonitoringStarted: false,
  isSessionReplayRecordingStarted: false,
  user: null,

  init: (user, sampleRate = 0, sessionReplaySampleRate = 0) => {
    if (
      !defaultConfig.dataDogRumAppId ||
      !defaultConfig.dataDogRumClientToken ||
      !navigator.cookieEnabled ||
      isSSRUserAgent()
    ) {
      return;
    }
    dataDogRum.user = user;
    datadogRum.init({
      applicationId: defaultConfig.dataDogRumAppId,
      clientToken: defaultConfig.dataDogRumClientToken,
      site: 'datadoghq.com',
      service: `web-${defaultConfig.env}`,
      env: defaultConfig.env,
      version: process.env.REACT_APP_VERSION || defaultConfig.version,
      sampleRate,
      sessionReplaySampleRate, // Set to 0 to disable replay recording.
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    dataDogRum.isMonitoringStarted = true;

    dataDogRum.updateUserDetails(user);
  },

  onUserLoggedIn: (user) => {
    if (
      !defaultConfig.dataDogRumAppId ||
      !defaultConfig.dataDogRumClientToken
    ) {
      return;
    }
    dataDogRum.updateUserDetails(user);
    if (!dataDogRum.isSessionReplayRecordingStarted) {
      datadogRum.startSessionReplayRecording();
      dataDogRum.isSessionReplayRecordingStarted = true;
    }
  },

  onUserLoggedOut: (user) => {
    dataDogRum.updateUserDetails(user);
    if (dataDogRum.isSessionReplayRecordingStarted) {
      datadogRum.stopSessionReplayRecording();
      dataDogRum.isSessionReplayRecordingStarted = false;
    }
  },

  updateUserDetails: (user) => {
    dataDogRum.user = user;
    const { deviceId } = getDevice();

    datadogRum.setUserProperty('id', user.userId);
    datadogRum.setUserProperty('type', user.type);
    datadogRum.setUserProperty('device', deviceId);
    datadogRum.setUserProperty('loggedIn', user.loggedIn);
  },
};
