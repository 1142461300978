import { CookieStorage } from 'amazon-cognito-identity-js';
import jsCookie from 'js-cookie';

import defaultConfig, { isLocalhost } from '../constants/defaultConfig';
import { isCookieEnabled } from '../utils/cookies';
import { currentDomain, isDomainSupported } from '../utils/isDomainSupported';

const CognitoIdentityKey = `CognitoIdentityServiceProvider.${defaultConfig.awsUserPoolWebClientId}`;

const cookieDomain =
  isLocalhost || isDomainSupported ? currentDomain : undefined;

function getCookieStorage() {
  return isCookieEnabled && cookieDomain
    ? new CookieStorage({
        domain: cookieDomain,
      })
    : undefined; // returns undefined to use localstorage
}

function migrateCognitoIdentityData() {
  const LocalStorage = window?.localStorage;

  if (LocalStorage && isCookieEnabled && cookieDomain) {
    const localStorageKeys = Object.keys(LocalStorage);
    const cognitoIdentityData = localStorageKeys.filter((key) =>
      key.includes(CognitoIdentityKey),
    );
    const expires = 365;
    const cookieOptions = { expires };
    if (isDomainSupported) cookieOptions.domain = `.${currentDomain}`;
    cognitoIdentityData.forEach((key) => {
      const value = LocalStorage.getItem(key);
      const cookieValue = key.includes('userData')
        ? value
            .replace(/"/g, '%22')
            .replace(/,/g, '%2C')
            .replace(/ /g, '%20')
        : value;
      const currentCookie = jsCookie.get(key);
      currentCookie === undefined &&
        jsCookie.set(key, cookieValue, cookieOptions);
      LocalStorage.removeItem(key);
    });
  }
}

export function getStorageObject(keyName) {
  const cookieStorage = getCookieStorage();
  migrateCognitoIdentityData();
  return cookieStorage ? { [keyName]: cookieStorage } : undefined;
}

export default getCookieStorage;
